<template>
  <div class="admin-wrap">
    <AdminMenu active-item="treatment-time" />
    <div class="admin-main">
      <AdminHeader title="服務管理：時間" backlink="javascript:history.back()" />
      <div class="service-course-add">
        <div class="employee-detail__setting">
          <h3>上下班時間設定</h3>
          <div class="employee-detail__working-hours">
            <table class="employee-detail__working-hours-table">
              <thead>
                <tr>
                  <th></th>
                  <th>上班時間</th>
                  <th></th>
                  <th>下班時間</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>平日</td>
                  <td>
                    <input type="time" v-model="systemSettings.weekdayStart">
                  </td>
                  <td>～</td>
                  <td>
                    <input type="time" v-model="systemSettings.weekdayEnd">
                  </td>
                  <td>
                    <button class="employee-detail__btn-clear" @click="systemSettings.weekdayStart=systemSettings.systemSettings='00:00'">清除</button>
                  </td>
                </tr>
                <tr>
                  <td>週六</td>
                  <td>
                    <input type="time" v-model="systemSettings.saturdayStart">
                  </td>
                  <td>～</td>
                  <td>
                    <input type="time" v-model="systemSettings.saturdayEnd">
                  </td>
                  <td>
                    <button class="employee-detail__btn-clear" @click="systemSettings.saturdayStart=systemSettings.saturdayEnd='00:00'">清除</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--醫療時間-->
        <div class="service-course-add__medical">
          <h3>醫療時間</h3>
          <div class="service-course-add__medical-wrap">
            <div class="service-course-add__medical-row">
              <div class="service-course-add__block">
                <label for="">間隔</label>
                <div class="service-course-add__medical-interval">
                  <input type="text" v-model="systemSettings.medicalInterval">
                  <span>分鐘</span>
                </div>
              </div>
              <div class="service-course-add__block">
              </div>
            </div>
            <table class="employee-detail__working-hours-table">
              <tbody>
                <tr>
                  <td>午餐</td>
                  <td>
                    <input type="time" v-model="systemSettings.medicalLunchStart">
                  </td>
                  <td>～</td>
                  <td>
                    <input type="time" v-model="systemSettings.medicalLunchEnd">
                  </td>
                  <td>
                    <button class="employee-detail__btn-clear" @click="systemSettings.medicalLunchStart=systemSettings.medicalLunchEnd='00:00'">清除</button>
                  </td>
                </tr>
                <tr>
                  <td>晚餐</td>
                  <td>
                    <input type="time" v-model="systemSettings.medicalDinnerStart">
                  </td>
                  <td>～</td>
                  <td>
                    <input type="time" v-model="systemSettings.medicalDinnerEnd">
                  </td>
                  <td>
                    <button class="employee-detail__btn-clear" @click="systemSettings.medicalDinnerStart=systemSettings.medicalDinnerEnd='00:00'">清除</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--醫療時間-->
        <!--美療時間-->
        <div class="service-course-add__beauty">
          <h3>美療時間</h3>
          <div class="service-course-add__beauty-wrap">
            <div class="service-course-add__beauty-row">
              <div class="service-course-add__block">
                <label for="">間隔</label>
                <div class="service-course-add__beauty-interval">
                  <input type="text" v-model="systemSettings.beautifyInterval">
                  <span>分鐘</span>
                </div>
              </div>
            </div>
            <table class="employee-detail__working-hours-table">
              <tbody>
                <tr>
                  <td>週間午餐</td>
                  <td>
                    <input type="time" v-model="systemSettings.beautifyLunchStart">
                  </td>
                  <td>～</td>
                  <td>
                    <input type="time" v-model="systemSettings.beautifyLunchEnd">
                  </td>
                  <td>
                    <button class="employee-detail__btn-clear" @click="systemSettings.beautifyLunchStart=systemSettings.beautifyLunchEnd='00:00'">清除</button>
                  </td>
                </tr>
                <tr>
                  <td>週間晚餐</td>
                  <td>
                    <input type="time" v-model="systemSettings.beautifyDinnerStart">
                  </td>
                  <td>～</td>
                  <td>
                    <input type="time" v-model="systemSettings.beautifyDinnerEnd">
                  </td>
                  <td>
                    <button class="employee-detail__btn-clear" @click="systemSettings.beautifyDinnerStart=systemSettings.beautifyDinnerEnd='00:00'">清除</button>
                  </td>
                </tr>
                <tr>
                  <td>週六午餐</td>
                  <td>
                    <input type="time" v-model="systemSettings.beautifyLunchStartSat">
                  </td>
                  <td>～</td>
                  <td>
                    <input type="time" v-model="systemSettings.beautifyLunchEndSat">
                  </td>
                  <td>
                    <button class="employee-detail__btn-clear" @click="systemSettings.beautifyLunchStartSat=systemSettings.beautifyLunchEndSat='00:00'">清除</button>
                  </td>
                </tr>
                <tr>
                  <td>週六晚餐</td>
                  <td>
                    <input type="time" v-model="systemSettings.beautifyDinnerStartSat">
                  </td>
                  <td>～</td>
                  <td>
                    <input type="time" v-model="systemSettings.beautifyDinnerEndSat">
                  </td>
                  <td>
                    <button class="employee-detail__btn-clear" @click="systemSettings.beautifyDinnerStartSat=systemSettings.beautifyDinnerEndSat='00:00'">清除</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--美療時間-->
        <button class="admin-btn-lg" @click="saveSettings">儲存</button>
      </div>
    </div>
  </div>
</template>

<script>
import AdminMenu from '../../components/AdminMenu'
import AdminHeader from '../../components/AdminHeader'
import { apiGetSystemSettings, apiSetSystemSettings } from '@/api/v1/admin'
import { formatDate } from '../../utils/utils'

export default {
  name: 'TreatmentTime',
  components: { AdminMenu, AdminHeader },
  data () {
    return {
      systemSettings: {}
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        const settings = await apiGetSystemSettings()
        this.systemSettings = settings
      } catch (e) {
        this.$swal('無資料', e, 'error')
      }
    },
    async saveSettings () {
      try {
        this.systemSettings.forDate = formatDate(new Date())
        const resp = await apiSetSystemSettings(this.systemSettings)
        console.log(resp.message)
        this.$swal('更新完成', resp.message, 'success')
      } catch (e) {
        this.$swal('更新失敗', e, 'error')
      }
    }
  }
}
</script>
<style scoped>
  .employee-detail__working-hours-table {
    margin-bottom: 0%;
  }
  .service-course-add__block {
    gap: 11rem;
  }
</style>
